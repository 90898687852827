! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);


jQuery(document).ready(function($) {
	// Mobile menu
	var $mobileMenuTrigger = $(".menu-icon");
	var $mobileMenu = $(".mobile-navigation-menu");

	$mobileMenu.mmenu({
		extensions: ["position-right"],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	var API = $mobileMenu.data("mmenu");

	$mobileMenuTrigger.click(function() {
		API.open();
		$mobileMenuTrigger.addClass("open");

		return false;
	});

	$mobileMenu.data('mmenu').bind('closed', function() {
		$mobileMenuTrigger.removeClass("open");
	});

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-body-section").fitVids({ ignore: "nofit" });
		$(".home-blog").fitVids({ ignore: "nofit" });
		$(".home-testimonial").fitVids({ ignore: "nofit" });
	}

	// Main nav drop-down menus
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-drop-toggle-link").click(function(event) {
			if ( !$(this).parents(".hasChildren").hasClass("open") ) {
				$(".menu-item").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".menu-item").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}

	// Accessible menu
	$(".desktop-navigation-menu").accessibleMenu();

	/* Sticky header */
	$(window).scroll(function() {
		$('.nav-section').toggleClass("sticky", $(this).scrollTop() > 5);
	});
});
